import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import civi from './assets/CIVICBRIDGE.jpg';
import flowsence from './assets/flowsense.png';
import Profile from './profile';

function NavBar() {
  return (
    <div className='top_header'>
    
        
          <div className='header_main_container'>

            <div className='logo_fs_container'></div>

            <div className='profile_container'>
                   <Profile className="profile_icon" style={{position: 'fixed' , width: '100%'}}/>
                   <div className='user_name'>Hi Santosh!</div>
            </div>
           
          </div>


          {/* <img src={civi} style={{ height: '70px', width: '70px', marginTop: '25px', marginLeft: '-20px' }} alt='CIVICBRIDGE'></img>
          <img src={flowsence} style={{ height: '70px', margin: 'auto' }} alt='talentOn'></img> */}
         
        
      
    
    </div>
  );
}
export default NavBar;