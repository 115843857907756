import './App.css';
import BasicTabs from './Menu';
import BaseScreen from './baseScreen';
import NavBar from './navbar';
function Screen() {
  return (
    <div className='screen'>
      <NavBar />
      <div>
        <div>
          <BasicTabs />
        </div>
        <BaseScreen />
      </div>
    </div>
  );
}

export default Screen;
