import * as React from 'react';
import {
    MemoryRouter,
    Routes,
    Route,
  } from "react-router-dom";
import SignIn from './signin';
import Screen from './screen';
const initialPath = '/';
const login = '/login';
const screen = '/screen';
export default function RouterScreen() {
    return (
        <MemoryRouter initialEntries={[{screen}]}>
        <Routes>
            <Route path={initialPath} element={<SignIn/>}></Route>
            <Route path={login} element={<SignIn/>}></Route>
            <Route path={screen} element={<Screen/>}></Route>
            {/* <Route path={about} element={<About/>}></Route> */}
        </Routes>
        </MemoryRouter>
    );
  }