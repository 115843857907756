import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Error from './error';

// import { createTheme, ThemeProvider } from '@mui/material/styles';
import { updateLoginInfo, selectIsAuthinticated } from './reducers/loginReducer';
import { setScreenName, screenNames } from './reducers/screenReducer';



function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const styles = {
    button: {
        fontSize: '24px',
    },
};

export default function SignInSide() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        showError: false, message: 'Incorrect user or password details !'
    })
    const authintication = async (formData) => {
        await fetch(`${window.API_BASE_URL}/signin`, {
            method: 'POST',
            'Content-Type': 'application/json',
            body: JSON.stringify(formData),
        })
            .then(data => data.json())
            .then((data) => {
                setLoading(false);
                if (data?.userId) {
                    dispatch(updateLoginInfo({ authenticated: true, ...data }));
                    window.localStorage.setItem('login', JSON.stringify({ authenticated: true, ...data }));
                    navigate('/screen');
                    setScreenName(screenNames.Dashboard)
                } else {
                    dispatch(updateLoginInfo({ authenticated: false, ...data }));
                    setError({ showError: true, message: data.errorMessage });
                }
            }).catch(err => {
                setLoading(false);
                console.log(err);
                dispatch(updateLoginInfo({ authenticated: false, errorMessage: 'Login failed!' }));
                setError({ showError: true, message: 'Incorrect user or password details !' });
            });
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true);
        await authintication({
            emailAddress: data.get('email'),
            password: data.get('password'),
        });
    };

    useEffect(() => {
        const loginInfo = window.localStorage.getItem('login');
        if (loginInfo) {
            dispatch(updateLoginInfo(JSON.parse(loginInfo)));
            navigate('/screen');
        }
    }, []);

    return (
        <Grid container component="main" sx={{ height: '100vh', overflow: 'hidden' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundColor: '#f1f1e7',
                    backgroundSize: 'cover',
                    backgroundPosition: 'left',
                }}
            >
                <img src=".\loginScreen.png" style={{
                    height: '90vh',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '5vh'
                }}></img>
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading}
                            style={styles.button}
                        >
                            {loading && <CircularProgress style={styles.circularProgress} size={20} />}
                            Sign In
                        </Button>
                        {error.showError && <Error showRetry={false} message={error.message} />}
                        <Copyright sx={{ mt: 3 }} />
                        <img src='./img1.jpg' style={{ height: '100%', maxHeight: '400px', marginTop: '30px' }} />
                    </Box>
                    {/* <Box xs={12} sm={8} md={5}></img></Box> */}
                </Box>

            </Grid>
        </Grid >
    );
}