const intitialState = {}

export const SET_ROW = 'SET_ROW';
export const CLEAR_SELECTED_ROW = 'CLEAR_SELECTED_ROW';

export const setRow = (data) => {
    return {
        type: SET_ROW,
        payload: {
            data
        }
    }
}

export const clearRow = () => {
    return {
        type: CLEAR_SELECTED_ROW,
    }
}

export default (state = intitialState, action) => {
    switch (action.type) {
        case SET_ROW:
            return {
                ...state,
                selectedRow: {
                    row: action.payload?.data?.row,
                    startDate: action.payload?.data?.startDate?.toString(),
                    endDate: action.payload?.data?.endDate?.toString(),
                }
            }
        case CLEAR_SELECTED_ROW: {
            return {
                ...state,
            }
        }
        default:
            return state;
    }
}

export const selectedRowData = (state) => {
    return state.trafon.dashboard?.selectedRow;
}