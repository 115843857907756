export const SET_SCREEN_NAME = "SET_SCREEN_NAME";
export const screenNames  = {
    Dashboard: 'dashboard',
    About: 'about',
    Chart: 'chart',
}
export const intitialState = {
    name: screenNames.Dashboard,
};

export function setScreenName(name) {
    return {
        type: SET_SCREEN_NAME,
        payload: {
            name
        }
    }
}
export default (state = intitialState, action) => {
    switch (action.type) {
        case SET_SCREEN_NAME:
            return {...state, name: action.payload.name };

        default:
            return state;
    }
};

export const selectScreenName = (state) => {
    return state.trafon.screen.name;
};