export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT = 'LOGOUT';
export const intitialState = {
    authenticated: false,
};

export function updateLoginInfo(payload) {
    return {
        type: LOGIN_USER,
        payload
    }
}

export function clearUserData() {
    return {
        type: LOGOUT
    }
}
export default (state = intitialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, ...action.payload };
        case LOGOUT:
            return {
                intitialState
            };
        default:
            return state;
    }
};

export const selectIsAuthinticated = (state) => state?.trafon?.login?.authenticated || false;
export const selectUserData = (state) => state?.trafon?.login || {};