import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearUserData, selectUserData } from './reducers/loginReducer';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import Divider from '@mui/material/Divider';

export default function Profile() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = useSelector(selectUserData);
    const logout = async () => {
        const header = new Headers();
        header.append('Content-Type', 'application/json');
        header.append('X-TRAFON-USER', userInfo.userId);
        header.append('X-TRAFON-SESSION', userInfo.sessionId);
        await fetch(`${window.API_URL}/signout`, {
            method: 'DELETE',
            headers: header,
        })
            .then(data => data.json())
            .then((data) => {
                dispatch(clearUserData());
                window.localStorage.removeItem('login');
                handleClose();
                navigate('/');
            }).catch(err => {
                console.log(err);
                dispatch(clearUserData());
                window.localStorage.removeItem('login');
                handleClose();
                navigate('/');
            });
    }

    return (
        <div>
            <Tooltip title="Profile" disableScrollLock>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{ margin: '10px 5px', top: '10px' }}
                >
                    <Avatar><PermIdentityOutlinedIcon sx={{ fontSize: '40px', color: '#293f6a' }} /></Avatar>
                </Button>
            </Tooltip>
            <Menu
                id="basic-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
                <MenuItem onClick={handleClose}>{`${userInfo.firstName} ${userInfo.lastName}`}</MenuItem>
                <Divider />
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
}