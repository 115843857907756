import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { setScreenName } from './reducers/screenReducer';
import moment from 'moment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { Button, Box } from '@mui/material';
function DateTimePickerValue(props) {
  const [value, setValue] = useState(moment(props.date));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
          label={props.label + ' @  ' + value.format('YYYY-MM-DD hh:mm:ss a')}
          value={value}
          views={props.showTime ? ['hours', 'minutes'] : ['year', 'month', 'day', 'hours', 'minutes']}
          disableFuture={true}
          shouldDisableDate={(date) => {
            if (!props.showTime) {
              return false;
            }
            if (date.format('YYYY-MM-DD') != value.format('YYYY-MM-DD')) {
              return true;
            } else {
              return false
            }
          }}
          onAccept={(newValue) => {
            setValue(newValue)
            props.onChange(newValue);
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default function DateTimeRangePicker(props) {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [startDateChange, setStartDateChange] = useState(false);
  const dispatch = useDispatch();

  const getDate = (startDate, endDate) => {
    const diff = moment(endDate).diff(moment(startDate))
    if (diff > 0) {
      setStartDate(startDate)
      setEndDate(endDate)
      props.onSubmit({ start: startDate, end: endDate });
    } else {
      return;
    }
  }

  const setLive = () => {
    const startT = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    const currentT = moment().format('YYYY-MM-DDTHH:mm:ss');
    setStartDate(startT);
    setEndDate(currentT);
    props.setLive({ start: startT, end: currentT });
  }

  useEffect(() => {
    if (startDateChange) {
      setEndDate(moment(startDate).hours(23));
      setStartDateChange(false);
    }
  }, [startDateChange])

  const debounce = _.debounce((arg) => dispatch(setScreenName(arg)), 300);

  const handleBack = () => {
    debounce(props.goBack)
  }

  return (
    <Box
      sx={{
        overflow: 'visible',
        display: 'flex',
        flexDirection: 'row',
        padding: '5px',
        justifyContent: 'flex-end',
        flexGrow: 1,
        backgroundColor: '#e0e0e0',
        marginTop: '-80px',
        ...props.style
      }}
    >
      <Button onClick={handleBack} style={{
        color: 'rgba(0, 0, 0, 0.87)',
        height: '60px',
        width: '120px',
        left: '10px',
        position: 'absolute',
      }} startIcon={<ArrowBackIcon />}>Back </Button>
      <DateTimePickerValue label='Start Date' date={startDate} showTime={false} onChange={(date) => {
        setStartDate(date);
        setStartDateChange(true);
      }} />

      <Box sx={{ margin: '10px 5px', padding: '10px' }}>To</Box>

      {!startDateChange && <DateTimePickerValue label='End Date' showTime={true} date={endDate} onChange={(date) => {
        setEndDate(date)
      }} />}
      <Button variant="outlined"
        sx={{
          height: '55px', width: '120px',
          margin: '8px 5px 10px',
          borderColor: 'rgba(0, 0, 0, 0.87)',
          color: 'rgba(0, 0, 0, 0.87)'
        }}
        onClick={() => getDate(startDate, endDate)}>
        Apply
      </Button>
      <Button variant="outlined"
        sx={{
          height: '55px', width: '120px',
          margin: '8px 5px 10px',
          borderColor: 'rgba(0, 0, 0, 0.87)',
          color: 'blue'
        }}
        onClick={() => setLive()}
      >Live</Button>
    </Box>
  )
};
