import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { selectScreenName, screenNames } from './reducers/screenReducer';
import { selectIsAuthinticated } from './reducers/loginReducer';
import './App.css';


const DynamicComponent = ({ componentName, ...props }) => {
    if (componentName === screenNames.Chart) {
        componentName = 'chart/'+componentName;
    }
    const Component = require(`./${componentName}`).default;
    return <Component {...props} />;
};

function BaseScreen() {
    const navigate = useNavigate();
    const screenName = useSelector(selectScreenName);
    const isAuthinticated = useSelector(selectIsAuthinticated);
    const [screen, setScreen] = useState(screenNames.Dashboard);
    useEffect(() => {
        setScreen(screenName);
    }, [screenName])
    useEffect(() => {
        if (!isAuthinticated) {
            navigate('/');
        }
    }, [isAuthinticated]);

    return (
        <div className="dashboard_container">
            {screen ?
                <DynamicComponent componentName={screen} />
                : <DynamicComponent componentName={screenNames.Dashboard} />}
        </div>
    );
}

export default BaseScreen;
