import { useState, useMemo, useEffect, useRef } from 'react';
import moment from 'moment';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Button, Box } from '@mui/material';


function DatePickerComponent(props) {
  const [value, setValue] = useState(moment(props.date));
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label={'Start Time @ ' + value.format('YYYY-MM-DD hh:mm:ss a')}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          onAccept={(value) => {
            props.onChange(value);
          }}
          disableFuture={true}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

function DateTimePickerValue(props) {
  const [value, setValue] = useState(moment(props.date));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={['DateTimePicker']}>
        <DateTimePicker
          label={'End Time @ ' + value.format('YYYY-MM-DD hh:mm:ss a')}
          views={['hours', 'minutes', 'seconds']}
          value={value}
          onChange={(newValue) => {
            setValue(newValue)
            props.onChange(newValue);
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

function TimePickerValue(props) {
  const [value, setValue] = useState(moment(props.date));

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={['TimePicker']}>
        <TimePicker
          label={'End Time @ ' + value.format('YYYY-MM-DD hh:mm:ss a')}
          value={value}
          onChange={(value) => {
            setValue(value);
            props.onChange(value);
          }}
        // onAccept={(value) => {
        //   props.onChange(value);
        //   console.log('onchange value', value);
        // }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

export function DateTimePickerComponent(props) {
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(moment(props.endDate));
  const [changeDate, setChangeDate] = useState(false);
  const [isLive, setIsLive] = useState(true);
  const isLiveRef = useRef(isLive);
  useEffect(() => {
    let time;
    if (!isLiveRef.current) {
      clearInterval(time);
    }
    time = setInterval(() => {
      if (!isLiveRef.current) {
        clearInterval(time);
      } else {
        setLive(true);
      }
    }, 1000 * 60);
    return () => {
      clearInterval(time);
    }
  }, [isLiveRef.current]);
  const getDate = (startDate, endDate) => {
    const diff = moment(endDate).diff(moment(startDate));
    if (diff > 0) {
      props.onSubmit({ start: startDate, end: endDate });
      isLive && setIsLive(false);
      isLiveRef.current=false;
    } else {
      return;
    }
  }
  useEffect(() => {
    if (changeDate) {
      if (!moment(startDate).isSame(moment(), 'day')) {
        setEndDate(moment(startDate).hours(23).minutes(0));
      }
      setChangeDate(false);
    }
  }, [changeDate]);

  const setLive = () => {
    const startT = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    const currentT = moment().format('YYYY-MM-DDTHH:mm:ss');
    props.setLive({ start: startT, end: currentT });
    setStartDate(startT);
    setEndDate(currentT);
    setIsLive(true);
    isLiveRef.current = true;
    setChangeDate(true);
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '5px',
        justifyContent: 'flex-end',
        marginRight: '10px',
        flexGrow: 1,
        backgroundColor: '#e0e0e0',
        ...props.style
      }}
    >
      {changeDate == false && <DatePickerComponent label='Start Date' date={startDate} onChange={(date) => {
        setStartDate(date);
        setChangeDate(true);
      }}
      />}

      <Box sx={{ margin: '10px 5px', padding: '10px' }}>To</Box>

      {changeDate == false && <TimePickerValue label='End Date' date={endDate} onChange={(date) => {
        setEndDate(date)
      }} />}

      <Button variant="outlined"
        sx={{
          height: '55px', width: '120px',
          margin: '8px 5px 10px',
          borderColor: 'rgba(0, 0, 0, 0.87)',
          color: 'rgba(0, 0, 0, 0.87)'
        }}
        onClick={() => getDate(startDate, endDate)}>
        Apply
      </Button>
      <Button variant="outlined"
        sx={{
          height: '55px', width: '120px',
          margin: '8px 5px 10px',
          borderColor: 'rgba(0, 0, 0, 0.87)',
          color: 'blue'
        }}
        onClick={() => setLive()}
      >Live</Button>
      <Box sx={{
        margin: '10px 5px 10px 30px',
        padding: '10px',
        fontWeight: 500,
        fontSize: '25px',
        color: '#1976d2'
      }}>{props.premisesName}</Box>
    </Box>
  )
};

