import { configureStore } from '@reduxjs/toolkit';
import {intitialState} from '../reducers/loginReducer';
import allReducer from '../reducers';
const preloadStore = {
    login: intitialState
}

const store = configureStore({
    reducer: {
        trafon: allReducer
    },
    preloadStore: preloadStore,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
export default store;
