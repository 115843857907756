import { Provider } from "react-redux";
import store from './store/configureStore';
import './App.css';
import RouterScreen from './Router';
const { REACT_APP_API_URL } = process.env;
console.log('REACT_APP_API_URL', REACT_APP_API_URL);
function App() {
  window.API_BASE_URL = REACT_APP_API_URL
  return (
    <Provider store={store}>
      <div className="App" style={{ width: '100%', overflow: 'auto' }}>
        <RouterScreen />
      </div>
    </Provider>
  );
}

export default App;
