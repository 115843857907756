import loginReducer from "./loginReducer";
import { combineReducers } from "@reduxjs/toolkit";
import screenReducer from "./screenReducer";
import dashboardReducer from "./dashboardReducer";

const allReducer = combineReducers({
    login: loginReducer,
    screen: screenReducer,
    dashboard: dashboardReducer,
});

export default allReducer;