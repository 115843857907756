import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectUserData } from './reducers/loginReducer';
import { screenNames, setScreenName } from './reducers/screenReducer';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DirectionsCarOutlinedIcon from '@mui/icons-material/DirectionsCarOutlined';
import TwoWheelerOutlinedIcon from '@mui/icons-material/TwoWheelerOutlined';
import DirectionsBusFilledOutlinedIcon from '@mui/icons-material/DirectionsBusFilledOutlined';
import ElectricBikeOutlinedIcon from '@mui/icons-material/ElectricBikeOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';


import Chip from '@mui/material/Chip';
import moment from 'moment';
import Loader from './loading';
import Error from './error';
import { DateTimePickerComponent } from './DatePicker';
import { setRow, selectedRowData } from './reducers/dashboardReducer';

// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  '&:nth-child(2)': {
    borderLeft: '1px solid #c3c8d4',
  },
  '&:nth-child(4)': {
    borderRight: '1px solid #c3c8d4',
  },
  '&:nth-child(7)': {
    borderRight: '1px solid #c3c8d4',
  },
  '&:nth-child(10)': {
    borderRight: '1px solid #c3c8d4',
  },
  '&:nth-child(13)': {
    borderRight: '1px solid #c3c8d4',
  },
  '&:nth-child(16)': {
    borderRight: '1px solid #c3c8d4',
  },
  '&:nth-child(19)': {
    borderRight: '1px solid #c3c8d4',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'lightyellow',
  },
}));

const columns = [
  { id: 'name', label: 'Name', size: 20, maxWidth: 20 },
  { id: 'car_in', label: 'IN', size: 10, maxWidth: 10 },
  { id: 'car_out', label: 'OUT', size: 10, maxWidth: 10 },
  { id: 'car_ip', label: 'In Premises', size: 10, maxWidth: 10 },
  { id: 'bike_in', label: 'IN', size: 10, maxWidth: 10 },
  { id: 'bike_out', label: 'OUT', size: 10, maxWidth: 10 },
  { id: 'bike_ip', label: 'In Premises', size: 10, maxWidth: 10 },
  // { id: 'auto_in', label: 'IN', size: 10, maxWidth: 10 },
  // { id: 'auto_out', label: 'OUT', size: 10, maxWidth: 10 },
  // { id: 'auto_ip', label: 'In Premises', size: 10, maxWidth: 10 },
  // { id: 'bus_in', label: 'IN', size: 10, maxWidth: 10 },
  // { id: 'bus_out', label: 'OUT', size: 10, maxWidth: 10 },
  // { id: 'bus_ip', label: 'In Premises', size: 10, maxWidth: 10 },
  // { id: 'tempo_in', label: 'IN', size: 10, maxWidth: 10 },
  // { id: 'tempo_out', label: 'OUT', size: 10, maxWidth: 10 },
  // { id: 'tempo_ip', label: 'In Premises', size: 10, maxWidth: 10 },
  // { id: 'truck_in', label: 'IN', size: 10, maxWidth: 10 },
  // { id: 'truck_out', label: 'OUT', size: 10, maxWidth: 10 },
  // { id: 'truck_ip', label: 'In Premises', size: 10, maxWidth: 10 },
  { id: 'oth_in', label: 'IN', size: 10, maxWidth: 10 },
  { id: 'oth_out', label: 'OUT', size: 10, maxWidth: 10 },
  { id: 'oth_ip', label: 'In Premises', size: 10, maxWidth: 10 },
];

const vehicleRecorded = [
  {
    "carIn": 691,
    "carOut": 666,
    "carInPrem": 25,
    "twoWheelerIn": 1122,
    "twoWheelerOut": 608,
    "twoWheelerInPrem": 514,
    "othersIn": 331,
    "othersOut": 236,
    "othersInPrem": 95,
    "premisesId": "MTvhK4kGnE",
    "premisesName": "Embassy Tech Square",
    "timeStamp": "2024-01-16T07"
  },
  {
    "carIn": 1622,
    "carOut": 1692,
    "carInPrem": -70,
    "twoWheelerIn": 2694,
    "twoWheelerOut": 1450,
    "twoWheelerInPrem": 1244,
    "othersIn": 620,
    "othersOut": 618,
    "othersInPrem": 2,
    "premisesId": "m7sAyQdtNx",
    "premisesName": "Embassy Tech Village",
    "timeStamp": "2024-01-16T08"
  }]

const alertStyle = {
  fontSize: '18px',
  margin: 'auto',
  justifyContent: 'center',
  marginTop: '10px'
}

let array = [];
const createData = (
  name,
  car_in,
  car_out,
  car_ip,
  bike_in,
  bike_out,
  bike_ip,
  auto_in,
  auto_out,
  auto_ip,
  bus_in,
  bus_out,
  bus_ip,
  tempo_in,
  tempo_out,
  tempo_ip,
  truck_in,
  truck_out,
  truck_ip,
  oth_in,
  oth_out,
  oth_ip,
  premisesId,
  gateId,
  gateName
) => {
  if (gateName) {
    name = gateName;
  }
  console.log('Others  bf', oth_in, oth_out, oth_ip);
  oth_in = oth_in + auto_in + bus_in + tempo_in + truck_in || 0;
  oth_out = oth_out + auto_out + bus_out + tempo_out + truck_out  || 0;
  oth_ip = oth_ip + auto_ip + bus_ip + tempo_ip + truck_ip || 0;
  console.log('Others af', oth_in, oth_out, oth_ip);
  return {
    name,
    car_in,
    car_out,
    car_ip,
    bike_in,
    bike_out,
    bike_ip,
    auto_in,
    auto_out,
    auto_ip,
    bus_in,
    bus_out,
    bus_ip,
    tempo_in,
    tempo_out,
    tempo_ip,
    truck_in,
    truck_out,
    truck_ip,
    oth_in,
    oth_out,
    oth_ip,
    premisesId,
    gateId,
    gateName
  };
}
const getArray = (obj) => {
  let array = [];
  let { premisesName,
    carIn,
    carOut,
    carInPrem,
    twoWheelerIn,
    twoWheelerOut,
    twoWheelerInPrem,
    autoIn,
    autoOut,
    autoPrem,
    busIn,
    busOut,
    busPrem,
    tempoIn,
    tempoOut,
    tempoPrem,
    truckIn,
    truckOut,
    truckPrem,
    othersIn,
    othersOut,
    othersInPrem,
    premises,
    gate,
    gateName } = obj;
  array.push(premisesName,
    Math.abs(carIn),
    Math.abs(carOut),
    Math.abs(carInPrem),
    Math.abs(twoWheelerIn),
    Math.abs(twoWheelerOut),
    Math.abs(twoWheelerInPrem),
    Math.abs(autoIn),
    Math.abs(autoOut),
    Math.abs(autoPrem),
    Math.abs(busIn),
    Math.abs(busOut),
    Math.abs(busPrem),
    Math.abs(tempoIn),
    Math.abs(tempoOut),
    Math.abs(tempoPrem),
    Math.abs(truckIn),
    Math.abs(truckOut),
    Math.abs(truckPrem),
    Math.abs(othersIn),
    Math.abs(othersOut),
    Math.abs(othersInPrem),
    premises,
    gate,
    gateName)
  return array;
}
const createRows = (obj) => {
  let rows = [];
  obj?.forEach(record => {
    array = [];
    rows.push(createData(...getArray(record)));
  });
  return rows || {};
}

export default function Dashboard() {
  const userInfo = useSelector(selectUserData);
  const selectedRow = useSelector(selectedRowData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rows, setRows] = useState();
  const [error, setError] = useState();
  const [errorMsg, setErrorMsg] = useState('Internal server error, please wait!');
  const [startDate, setStartDate] = useState(selectedRow?.startDate || moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'));
  const [endDate, setEndDate] = useState(selectedRow?.endDate || moment().format('YYYY-MM-DDTHH:mm:ss'));
  const [isDateSelected, setDateSelected] = useState(false)
  
  const fetchPremises = async (startDate, endDate) => {
    let currentT, startT;
    if (isDateSelected) {
      startT = moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      currentT = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');
    } else {
      startT = moment(startDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
      currentT = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');
      setStartDate(startT);
      setEndDate(currentT);
    }
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('X-TRAFON-USER', userInfo.userId);
    header.append('X-TRAFON-SESSION', userInfo.sessionId);

    await fetch(`${window.API_BASE_URL}/premises/trafon?start=${startT}&end=${currentT}`, {
      method: 'GET',
      headers: header,
    })
      .then(data => data.json())
      .then((data) => {
        if (data.status === 'error') {
          setError(true);
          setErrorMsg(`${data.errorCode}: ${data.errorMessage}`);
          if (data.responseCode == '401') {
            window.localStorage.removeItem('login');
            navigate('/login');
          }
        }
        setRows(createRows(data));
      }).catch(err => {
        console.log(err);
        setError(true)
      });
  }

  useEffect(() => {
    let timer;
    if (isDateSelected) {
      clearInterval(timer);
      fetchPremises(startDate, endDate);
    } else {
      fetchPremises(startDate, endDate);
      timer = setInterval(() => {
        fetchPremises(startDate, endDate);
      }, 1000 * 60);
    }
    return () => {
      clearInterval(timer);
    }
  }, [isDateSelected, startDate, endDate]);

  const getSelectedDate = (value) => {
    setStartDate(value.start);
    setEndDate(value.end);
    setDateSelected(true);
  };
  const setLive = (value) => {
    setStartDate(value.start);
    setEndDate(value.end);
    setDateSelected(false);
  };

  const onClickRow = (row) => {
    if (!row) { return; }
    dispatch(setRow({ row, startDate, endDate }));
    dispatch(setScreenName(screenNames.Chart));
  }

  return (
    <div className='right_db_Container'>
      <h1 className='main_heading'>Dashboard</h1>
      {rows ?
        <Paper>

        <div className='filter_container'>

          <div className='filter_info_container'>
            <div className='filter_heading_main_container'>
                Time based Filter and Product
            </div>
            <div className='info_text'>
              Please select the Date picker and Time to check the data
            </div>
          </div>

          <div className='premises_main_container'>

          <div className='premises_info'>
            <div className='premises_image'></div>
           
            <div className='premises_name'  >
                 {userInfo.premisesName}
            </div>
          </div>

          <div className='filter_main_container'>

            {/* <div className='start_date_container'>
              <DesktopDatePicker defaultValue={startDate} />
            </div> */}
            <div className="sd_container">
              {/* <label>Start Date</label> */}
            </div>
          
          <DateTimePickerComponent
                
                startDate={startDate}
                endDate={endDate}
                onSubmit={(dateRange) => getSelectedDate(dateRange)}
                setLive={(liveDate) => setLive(liveDate)}
                style={{ justifyContent: 'flex-start', marginRight: '0' }} />

          </div>

          </div>

        </div>

        <div className='table_main_container'>

          <div className='table_heading'>
          Traffic data for {userInfo.premisesName}
          </div>

          <TableContainer sx={{ maxHeight: 600, overflow: 'hidden', borderTop: '1px solid #c3c8d4' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>
                    {/* <Chip  label={'Recorded Time @ ' + .format('hh:mm A') } /> */}
                    {/* <DatePickerValue date = {date} onChange={(value) => getSelectedDate(value)}/> */}

                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={3} style={{ borderLeft: "1px solid black", borderRight: "1px solid black", borderBottom: "1px solid black" }}>
                    <Chip icon={<DirectionsCarOutlinedIcon />} label="Car" />
                  </StyledTableCell>
                  <StyledTableCell align="center" colSpan={3} style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>
                    <Chip icon={<TwoWheelerOutlinedIcon />} label="Bike" />
                  </StyledTableCell>
                  {/*<StyledTableCell align="center" colSpan={3} style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>
                    <Chip icon={<ElectricRickshawOutlinedIcon />} label="Auto" />
                  </StyledTableCell>*/}
                  {/* <StyledTableCell align="center" colSpan={3} style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>
                    <Chip icon={<DirectionsBusFilledOutlinedIcon />} label="Bus" />
                  </StyledTableCell> */}
                  {/* <StyledTableCell align="center" colSpan={3} style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>
                    <Chip icon={<AirportShuttleOutlinedIcon />} label="Tempo" />
                  </StyledTableCell> */}
                  {/* <StyledTableCell align="center" colSpan={3} style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>
                    <Chip icon={<LocalShippingOutlinedIcon />} label="Truck" />
                  </StyledTableCell> */}
                  <StyledTableCell align="center" colSpan={3} style={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}>
                    <Chip icon={<ElectricBikeOutlinedIcon />} label="Others" />
                  </StyledTableCell>
                </TableRow>
                <StyledTableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ top: 57, minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {rows
                  .map((row) => {
                    return (
                      <StyledTableRow hover role="checkbox" tabIndex={-1} key={row.name} onClick={() => onClickRow(row)}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <StyledTableCell key={column.id} align={column.align}>
                              {value}
                            </StyledTableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <div className='bangalore_police_logo_center'></div>
        
        </div>

        </Paper>
        : error ? <Error showRetry={false} message={errorMsg} style={{ height: '100vh' }} /> : <Loader />
      }
    </div>);
}