import './chart.css';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Box from '@mui/material/Box';
import { Line } from 'react-chartjs-2';
const pstyle = {
  width: '100%',
  margin: 'auto',
  color: 'white',
  textAlign: 'center',
  padding: '5px'
}
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const getOptions = (type) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Char',
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Timeline',
          color: '#911',
          font: {
            family: 'Comic Sans MS',
            size: 20,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 }
        }
      },
      y: {
        display: true,
        title: {
          display: true,
          text: `Number Of ${type}`,
          color: '#191',
          font: {
            family: 'Comic Sans MS',
            size: 20,
            weight: 'bold',
            lineHeight: 1.2,
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 }
        }
      },
    },
  };
  return options;
}

export function LineChart(props) {
  const [lodding, setLoading] = React.useState(false);
  const [data, setData] = React.useState();

  const makeData = (type, timeStamp, cardData) => {
    let chartData = {
      labels: timeStamp,
      datasets: [
        {
          label: 'In',
          data: cardData?.in,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        {
          label: 'Out',
          data: cardData?.out,
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.5)',
        }
      ],
    };
    setData(chartData);
    setLoading(false);
  }

  const makeDataInPremises = (type, timeStamp, cardData) => {
    let chartData = {
      labels: timeStamp,
      datasets: [
        {
          label: 'In Premises',
          data: cardData?.prem,
          borderColor: 'rgb(75, 201, 12)',
          backgroundColor: 'rgba(52, 89, 33, 0.5)',
        },
      ],
    };
    setData(chartData);
    setLoading(false);
  }
  React.useEffect(() => {
    if (!lodding) {
      if (props.type === 'inPremises') {
        setLoading(true);
        makeDataInPremises(props.type, props.timeStamp, props.carData);
      } else {
        setLoading(true);
        makeData(props.type, props.timeStamp, props.carData);
      }
    }
    return () => {
      setLoading(false);
    }
  }, [lodding]);
  return (
    !lodding && data ? <Box sx={{border: '1px solid white'}}>
      <p style={pstyle}>{props.vehicleName} {props.type === 'inPremises'? 'In Premises': 'In & Out'}</p>
      <Line options={getOptions(props.vehicleName)} data={data} width={1000} height={220} key={props.type} /> 
    </Box> : <></>
  );
}