import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';

export default function Loader() {
  return (
    <Paper sx={{display: 'flex', justifyContent: "center", alignItems: 'center', height: '100vh', background: 'transparent'}}>
      <CircularProgress />
    </Paper>
  );
}