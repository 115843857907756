import moment from 'moment';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import DateTimeRengePicker from '../DateTimePicker';
import Error from '../error';
import Loader from '../loading';
import { selectUserData } from '../reducers/loginReducer';
import { LineChart } from './line';
import { selectedRowData } from '../reducers/dashboardReducer';
import { screenNames } from '../reducers/screenReducer';

const getTimeStamp = (data) => {
  let time = data?.trafonData?.map(d => moment(d.timeStamp2).format('lll'));
  return time;
}
const getCarData = (data) => {
  let carIn = data?.trafonData?.map(d => Math.abs(d.vehicleIn));
  let carOut = data?.trafonData?.map(d => Math.abs(d.vehicleOut));
  let carInPrem = data?.trafonData?.map(d => Math.abs(d.vehiclePrem));
  return { in: carIn, out: carOut, prem: carInPrem };
}
Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});
export default function Chart() {
  const userInfo = useSelector(selectUserData);
  const selectedRow = useSelector(selectedRowData);
  const [rowData, setRowData] = useState(selectedRow);
  const rowDataRef = useRef(rowData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [startDate, setStartDate] = useState(moment(selectedRow.startDate).hours(6).format('YYYY-MM-DDTHH:mm:ss'));
  const [endDate, setEndDate] = useState(moment(selectedRow.endDate).format('YYYY-MM-DDTHH:mm:ss'));
  const [refresh, setRefresh] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [showRetry, setShowRetry] = useState(true);
  const [errorMessage, setErrorMessage] = useState('Internal server error, please retry!');
  const [graphPageData, setGraphPageData] = useState();

  // const vehicleType = ['car', 'bus', 'tempo', 'truck'];
  const vehicleType = ['car', 'twowheeler'];
  useEffect(() => {
    console.log('chat mount');
    return () => {
      console.log('chat unmount');
    }
  }, []);
  useEffect(() => {
    setRowData(selectedRow.row);
    rowDataRef.current = selectedRow.row;
    setStartDate(moment(selectedRow.startDate).hours(6).format('YYYY-MM-DDTHH:mm:ss'));
    setEndDate(moment(selectedRow.endDate).format('YYYY-MM-DDTHH:mm:ss'));
    setRefresh(true);
  }, [selectedRow])

  const fetchAllData = async (type = 'car') => {
    console.log('fetchAllData type', type)
    if (!rowDataRef.current) { return; }
    let duration = moment.duration(moment(endDate).diff(moment(startDate)));
    var minutes = duration.asMinutes();
    const interval = Math.floor(minutes / 30);
    //Math.floor(minutes/30);
    const header = new Headers();
    header.append('Content-Type', 'application/json');
    header.append('X-TRAFON-USER', userInfo.userId);
    header.append('X-TRAFON-SESSION', userInfo.sessionId);
    let queryParam = `start=${startDate}&end=${endDate}&interval=${interval}&vehicle=${type}`;
    if (rowDataRef.current?.premisesId) {
      queryParam = `${queryParam}&premises=${rowDataRef.current.premisesId}`;
    }
    if (rowDataRef.current?.gateId) {
      queryParam = `${queryParam}&gate=${rowDataRef.current.gateId}`;
    }
    const url = `${window.API_BASE_URL}/trafon?${queryParam}`

    return fetch(url, {
      method: 'GET',
      headers: header,
    })
  };

  const fetchData = () => {
    const promisedUsers = vehicleType.map((id) =>
      fetchAllData(id).then((response) => response.json())
    );
    Promise.all(promisedUsers).then((results) => {
      const resultObject = vehicleType.reduce((obj, key, index) => {
        obj[key] = results[index];
        return obj;
      }, {});
      setGraphPageData(resultObject);
      setLoading(true);
    });
  }

  useEffect(() => {
    let timer;
    if (refresh) {
      setUpdatePage(true);
      timer = setTimeout(() => {
        clearTimeout(timer);
        fetchData();
      }, 1000 * 60);
    } else {
      clearTimeout(timer);
      setUpdatePage(true);
      setLoading(false);
      fetchData();
    }
    return () => {
      clearTimeout(timer);
    }
  }, [refresh, startDate, endDate]);


  const getDate = (dateRange) => {
    setStartDate(moment(dateRange.start).format('YYYY-MM-DDTHH:mm:ss'));
    setEndDate(moment(dateRange.end).format('YYYY-MM-DDTHH:mm:ss'));
    setLoading(true);
    setRefresh(false);
    setUpdatePage(false);
  }
  const setLive = (dateRange) => {
    setStartDate(moment(dateRange.start).hours(6).format('YYYY-MM-DDTHH:mm:ss'));
    setEndDate(moment(dateRange.end).format('YYYY-MM-DDTHH:mm:ss'));
    setRefresh(true);
    setLoading(true)
    setUpdatePage(false);
  }

  const retry = () => {
    setLoading(false);
    setError(false);
    fetchData();
  }

  return (
    updatePage ? <div style={{ marginTop: '80px', width: '90vw' }}><DateTimeRengePicker
      startDate={startDate}
      endDate={endDate}
      onSubmit={(dateRange) => getDate(dateRange)}
      setLive={(liveDate) => setLive(liveDate)}
      goBack={screenNames.Dashboard}
    />

      {loading && !error ?
        graphPageData && Object.keys(graphPageData).map((keyName, i) => (<div>
          <LineChart type='hourly' vehicleName={keyName === 'twowheeler' ? 'Bike': keyName.capitalize()} timeStamp={getTimeStamp(graphPageData[keyName])} carData={getCarData(graphPageData[keyName])} />
          <LineChart type='inPremises' vehicleName={keyName === 'twowheeler' ? 'Bike': keyName.capitalize()} timeStamp={getTimeStamp(graphPageData[keyName])} carData={getCarData(graphPageData[keyName])} />
        </div>))
        : error ?
          <>
            <Error showRetry={showRetry} message={errorMessage}
              handleRetry={() => retry()}  style={{height: '100vh'}}/>
          </> : <Loader />}
    </div> : <Loader />
  );
};