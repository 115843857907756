import * as React from 'react';
// import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';


export default function Error(props) {

  return (
    <Stack sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', background: 'transparent' }} style={props.style}>
      <Alert severity="error" icon={<NotificationImportantOutlinedIcon sx={{fontSize: '35px'}} />} sx={{
        fontSize: '24px'
      }}> {props.message} 
      {props.showRetry ?
        <IconButton onClick={props.handleRetry} color="primary"
          sx={{
            color: "white",
            marginLeft: '10px',
            backgroundColor: "#784734",
            borderRadius: "50%"
          }}>
          <ReplayIcon />
        </IconButton>
        : null}</Alert>
    </Stack>
  );
}