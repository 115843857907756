import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {screenNames, setScreenName} from './reducers/screenReducer';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function VerticalTabs() {
  const [value, setValue] = React.useState(screenNames.Dashboard);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    dispatch(setScreenName(newValue));
  };

  return (

    <aside className='side_nav'>

      <div className='extended_menu'></div>

      <div className='side_nav_child_container'>
        <div className='side_nav_child'>
          
              <Tabs className='side_nav_internal'
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
            >
              <Tab className='aside_options aside_dashboard' label="" value={screenNames.Dashboard} title='Dashboard'/>
              <Tab className='aside_options aside_about' label=""  value={screenNames.About} title='info'/>
            </Tabs>





        </div>

      </div>

 
      <div className='btm_logout' title='Logout'></div>

            
      

    </aside>
  );
}